import React from "react";

// UI components
import { Link } from "react-router-dom";
import appIcon from "../assets/ca-app-icon.svg";

class Error extends React.Component {
  render() {
    return (
      <div className="error-container">
        <div className="error">
          <div className="app-icon">
            <img src={appIcon} alt="companyapp logo" />
          </div>
          {this.props.status ?? (
            <h3 className="error__status">
              <span>Error code: </span>
              {this.props.status}
            </h3>
          )}
          <p className="error__message">{this.props.message}</p>
          <Link to={"/"} className="btn btn-primary mt-5">
            Back to Home
          </Link>
        </div>
      </div>
    );
  }
}

export default Error;
