const defaultState = {
  showNavigation: true,
};

export const navigationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'TOGGLE_NAVIGATION':
      return {
        showNavigation: action.payload,
      };

    default:
      return state;
  }
};