import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { ReactComponent as MusicNoteIcon } from '../assets/music-notes.svg';
import { RecordVoiceOver } from '@mui/icons-material';
import { Col, Row } from 'react-bootstrap';

export default function AudioPrompt(props) {

  return (
    <Dialog className='audio-prompt-dialog'
      open={props.open}
      onClose={() => props.close()}
    >
      <DialogTitle className='audio-prompt-title'>
        This presentation contains sound!
      </DialogTitle>
      <DialogContent className='audio-prompt-content'>
        <Row>
          <Col>
            {props.hasPresentationAudio &&
              <Row className='audio-prompt-content-row'>
                <Col xs={6} className='audio-prompt-content-col'>
                  <MusicNoteIcon className='icon' /> Presentation soundtrack
                </Col>
              </Row>
            }
            {props.hasSlideAudio &&
              <Row className='audio-prompt-content-row'>
                <Col xs={6} className='audio-prompt-content-col'>
                  <RecordVoiceOver className='icon' /> Slide voice overs
                </Col>
              </Row>
            }
            <Row className='audio-prompt-content-row'>
              <Col xs={8} className='audio-prompt-content-col'>
                If a concern, please mute your speakers before dismissing this message.
              </Col>
            </Row>
            <Row className='audio-prompt-content-row'>
              <Col xs={4} className='audio-prompt-content-col dismiss'>
                <Button onClick={() => props.close()} className='dismiss-btn'>
                  Dismiss
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  )

}