import React from 'react';

// Modules
import {decode} from 'html-entities';

export default class PostTitle extends React.Component {
  render() {
    let title = this.props.title;
    let style = this.props.className ? `${this.props.className} title` : 'title';

    try {
      title = decode(title)
    } catch (error) {
      // console.log('cannot decode')
    }

    if (this.props.maxLength) {
      let TITLE_MAX_LENGTH = this.props.maxLength;

      if (title.length > TITLE_MAX_LENGTH) {
        title = title.substring(0, TITLE_MAX_LENGTH);
        title = title.substring(0, title.lastIndexOf(' '));
        title += '...';
      }
    }

    const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

    const safeHeading = this.props.headingLevel ? this.props.headingLevel.toLowerCase() : '';

    // If not valid heading, default to <p>
    const Title = validHeadingLevels.includes(safeHeading) ? safeHeading : 'p';

    return (
      <Title className={style}>{title}</Title>
    );
  }
}