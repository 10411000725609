import React, { useEffect, useState } from 'react';

// Modules

// App
import { getOnlineNode } from '../../../core/getNode';
import NoData from '../../../partials/noData';
import Presentation from '../partials/presentation/view/presentation';

// UI components
import {
  AppBar, Button, CircularProgress, Dialog,
  DialogContent, Stack,
  Toolbar,
  Typography,
  Alert,
} from '@mui/material';
import { Col, Container, Row } from 'react-bootstrap';

export default function CreatePresentationFromTemplate(props) {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  /**
   * Data
   */
  const [presentations, setPresentations] = useState([]);
  const [selectedPresentation, setSelectedPresentation] = useState(null);

  useEffect(() => {
    let path = `api/user_presentations?template=true`;

    getOnlineNode(path)
      .then((response) => {
        setPresentations(response.data.rows);
        setLoading(false);
      })
      .catch((_error) => {
        setError(true);
      });
  }, []);

  const onClose = () => {
    props.onClose();
  }

  const onPresentationSelected = () => {
    // do logic here
    if (selectedPresentation.id) {
      setLoading(true);

      const path = `/api/presentation_clone/${selectedPresentation?.id}`;

      getOnlineNode(path)
        .then(() => {
          setSelectedPresentation(null);
          props.onClose();
          props.onSuccess();
          setLoading(false);
        })
        .catch((_error) => {
          setLoading(false);
          setError(true);
        });
    }
  }

   return (
    <Dialog
      fullScreen={true}
      open={props.isOpen}
      onClose={props.onClose}
    >
      <AppBar sx={{ position: 'relative', backgroundColor: 'primary.light', color: 'solid.dark' }} >
        <DialogContent sx={{ paddingTop: 1, paddingBottom: 1 }}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Row className='w-100'>
              <Col>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {`Create presentation from template`}
                </Typography>
              </Col>
              <Col className='d-flex justify-content-end align-items-center'>
                <Row>
                  <Col className='d-flex justify-content-end'>
                    <Stack direction='row' spacing={2}>
                      <Button
                        sx={{
                          backgroundColor: 'primary.contrastText', 
                          width: '130px',
                          height: '25px',
                          textTransform: 'none',
                          fontWeight: '400',
                          fontSize: 14
                        }}
                        variant='outlined'
                        onClick={() => {
                          onClose();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: 'primary.confirm',
                          width: '130px',
                          height: '25px',
                          textTransform: 'none',
                          fontWeight: '400',
                          fontSize: 14
                        }}
                        disabled={selectedPresentation ? false : true}
                        variant='contained'
                        onClick={() => {
                          onPresentationSelected();
                        }}
                      >
                        Create
                      </Button>
                    </Stack>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Toolbar>
        </DialogContent>
      </AppBar>

      <DialogContent sx={{ textAlign: isLoading ? 'center' : '' }}>
        {isLoading ? (
          <CircularProgress color='primary' />
        ) : (
          <>
            <Container fluid className='add-presentation-container'>
              {isError && (
                <Row className='mt-4'>
                  <Col xs={12} sm={6}>
                    <Alert severity='error'>An error occurred. Please try again.</Alert>
                  </Col>
                </Row>
              )}
              <Row className='mt-4'>
                <Col xs='12 mb-2'>
                  <Typography variant='h6'>IMPORTANT</Typography>
                </Col>
                <Col xs='6'>
                  <Typography>Please note: the '<b>Hotel Presentation Starter template</b>' should only be used if you wish to create a presentation to showcase your hotel.</Typography><br />
                  <Typography>If you want to create a presentation for any other purpose, please return to the presentation list page and choose 'Create presentation >> New blank presentation' instead.</Typography>
                  </Col>
              </Row>
              <Row className='mt-4'>
                {presentations.length > 0 ? (
                  <>
                    {presentations.map((presentation) => {
                      return (
                        <Presentation
                          isLibrary={true}
                          key={`presentation-${presentation.id}`}
                          user={props.user}
                          item={presentation}
                          selector
                          onSelected={() => {
                            setSelectedPresentation(presentation);
                          }}
                          isSelected={selectedPresentation?.id === presentation.id ? true : false}
                        />
                      )
                    })}
                  </>
                ) : (
                  <NoData activeIndex={0} />
                )}
              </Row>
            </Container>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
