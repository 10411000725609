import {createStore, applyMiddleware, compose} from 'redux';
import ReduxThunk from 'redux-thunk';
import authReducer from './authReducer';

const middlewares = [ReduxThunk];

const store = createStore(
  authReducer,
  {}, // default state of the application
  compose(applyMiddleware(...middlewares)),
);

export default store;