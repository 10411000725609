import React, { useEffect, useState } from 'react';
import { IconButton, Box, Popover, Slider, Stack } from '@mui/material';
import { PlayArrow, VolumeUp, VolumeOff } from '@mui/icons-material';
import { Col, Row } from 'react-bootstrap';
import PauseIcon from '@mui/icons-material/Pause';


export default function AudioPlayer(props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [playerAudio, setPlayerAudio] = useState(new Audio());
  const [isMuted, setIsMuted] = useState(false);
  const [tabHasFocus, setTabHasFocus] = useState(false);
  const [containerTop, setContainerTop] = useState(0);
  const [containerLeft, setContainerLeft] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [playerWidth, setPlayerWidth] = useState(0);
  const [showControls, setShowControls] = useState(false);
  const [audioTime, setAudioTime] = useState(0);

  let viewContainerTop = document.querySelector('.viewer-controls-container')?.offsetTop
  let viewContainerLeft = document.querySelector('.viewer-controls-container')?.offsetLeft
  let viewContainerWidth = document.querySelector('.viewer-controls-container')?.offsetWidth
  let controlsContainerWidth = document.querySelector('#controls-container')?.offsetWidth

  useEffect(() => {
    const handleFocus = () => {
      setTabHasFocus(true);
    };

    const handleBlur = () => {
      setTabHasFocus(false);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    window.addEventListener('click', handleFocus);


    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    if (document.querySelector('.viewer-controls-container') && document.querySelector('#controls-container')) {
      setContainerTop(viewContainerTop);
      setContainerLeft(viewContainerLeft);
      setContainerWidth(viewContainerWidth);
      setPlayerWidth(controlsContainerWidth);
    }
  }, [
    viewContainerTop,
    viewContainerLeft,
    viewContainerWidth,
    controlsContainerWidth
  ]);

  useEffect(() => {
    setPlayerAudio(new Audio(props.audio));
  }, [props.audio]);

  useEffect(() => {
    playerAudio.addEventListener('ended', () => setIsPlaying(false));
    playerAudio.addEventListener("timeupdate", (event) => {
      setAudioTime(event.target.currentTime);
    })
    
    return () => {
      playerAudio.removeEventListener('ended', () => setIsPlaying(false));
      playerAudio.removeEventListener('timeupdate', () => { });
      setShowControls(false);
    };
  },[isPlaying]);

  useEffect(() => {
    if (!props.editMode) {
      if (props.isSlideAudio) {
        if (props.audioTrigger && tabHasFocus && props?.autoPlay && !props.promptVisible) {
          setShowControls(true);
          togglePlay();
        }
        if (isPlaying && (!props.audioTrigger || !tabHasFocus)) {
          // stopAudio();
          playerAudio.pause();
          setIsPlaying(!isPlaying);
        }
      }
      else {
        if (tabHasFocus && props?.autoPlay && !props.promptVisible) {
          setShowControls(true);
          togglePlay();
        }
        if (isPlaying && !tabHasFocus) {
          playerAudio.pause();
          setIsPlaying(!isPlaying);
          // stopAudio();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.audioTrigger, tabHasFocus, props?.promptVisible]);

  // reveal controls override
  useEffect(() => {
    if (props.playOverride !== null) {
      if (isPlaying || props.playOverride) {
        setShowControls(true)
        togglePlay();
      }
      if (isPlaying && (!tabHasFocus)) {
        stopAudio();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.playOverride]);

  // pass audio status
  useEffect(() => {
    props.audioPlaying(isPlaying);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useEffect(() => {
    playerAudio.muted = isMuted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMuted]);

  useEffect(() => {
    playerAudio.loop = props?.audio?.loop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.audio?.loop]);

  const togglePlay = () => {

    if (isPlaying) {
      playerAudio.pause();
      setIsPlaying(!isPlaying);
    }
    else if (!isPlaying && tabHasFocus) {
      playerAudio.play();
      setIsPlaying(!isPlaying);
    }
  }

  const stopAudio = () => {
    playerAudio.pause();
    playerAudio.currentTime = 0;
    setIsPlaying(!isPlaying);
  }

  const audioPlayer = () => {
    return (
      <Stack
        className='audio-player'
        direction='row'
        justifyContent='start'
        sx={{
          width: props.isSlideAudio ? playerWidth : '200px',
        }}
      >
        <Row className='d-flex w-100 m-0 justify-content-center'>
          <Col className={`player-section player p-0`}>
            <Row>
              <Col xs='auto' className='part btn'>
                <IconButton className='play-btn control-btn' onClick={() => { togglePlay() }}> {isPlaying ? <PauseIcon /> : <PlayArrow />}</IconButton>
              </Col>
              <Col className='part track'>
                <Slider
                  className='control-slider'
                  size='small'
                  min={0}
                  step={0.10}
                  value={audioTime}
                  max={playerAudio.duration}
                  onChange={(event) => {
                    // setAudioTime(event.target.value);
                    playerAudio.currentTime = event.target.value;
                  }}
                />
              </Col>
              <Col xs='auto' className='part btn'>
                <IconButton
                  className='mute-btn control-btn'
                  onClick={() => {
                    setIsMuted(!isMuted)
                  }}>
                  {isMuted ? <VolumeOff /> : <VolumeUp />}
                </IconButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Stack>
    )
  }

  return (
    <>
      {props.isSlideAudio ?
        <Popover
          open={props.showControls || showControls}
          anchorReference="anchorPosition"
          anchorPosition={{
            top: containerTop + 50,
            left: parseInt(containerLeft),
          }}
          onClose={() => props.hideSlideAudioControls()}
          sx={{
            "& .MuiPaper-root": {
              background: 'transparent',
              boxShadow: 'none',
              width: containerWidth,
              overflow: 'visible',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className={`player-container ${props.isSlideAudio ? 'slide-audio' : ''} ${props.showControls ? 'visible' : ''} `}
        // className={`player-container ${props.isSlideAudio ? 'slide-audio' : ''} visible `}
        >
          {audioPlayer()}
        </Popover>
        :
        <Box className='player-container'>
          {audioPlayer()}
        </Box>
      }
    </>
  );
};