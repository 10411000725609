import Table from './table';
import * as $ from './utils/dom';
import { IconTable, IconTableWithHeadings, IconTableWithoutHeadings } from '@codexteam/icons';

/**
 * @typedef {object} TableConfig - configuration that the user can set for the table
 * @property {number} rows - number of rows in the table
 * @property {number} cols - number of columns in the table
 */
/**
 * @typedef {object} Tune - setting for the table
 * @property {string} name - tune name
 * @property {HTMLElement} icon - icon for the tune
 * @property {boolean} isActive - default state of the tune
 * @property {void} setTune - set tune state to the table data
 */
/**
 * @typedef {object} TableData - object with the data transferred to form a table
 * @property {boolean} withHeading - setting to use cells of the first row as headings
 * @property {string[][]} content - two-dimensional array which contains table content
 */

/**
 * Table block for Editor.js
 */
export default class TableBlock {
  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to press Enter inside the CodeTool textarea
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {TableData} data — previously saved data
   * @param {TableConfig} config - user config for Tool
   * @param {object} api - Editor.js API
   * @param {boolean} readOnly - read-only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = config;
    this.data = {
      withHeadings: data && data.withHeadings ? data.withHeadings : false,
      content: data && data.content ? data.content : [],
    };
    this.table = null;
    this.csvFileInput = null;
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: IconTable,
      title: 'Table'
    };
  }

  /**
   * Plugins styles
   *
   * @returns {{settingsWrapper: string}}
   */
  static get CSS() {
    return {
      settingsWrapper: 'tc-settings',
      tunesWrapper: 'tc-tunes',
    };
  }

  /**
   * CSV file handler
   */
  csvFileHandler(event) {
    event.preventDefault();

    let file = event.target.files[0];
    const that = this;

    if (file) {
      let reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = function (e) {

        const csvFileContent = e.target.result;
        const delimiter = ',';

        const headers = csvFileContent.slice(0, csvFileContent.indexOf("\n")).split(delimiter);
        const rows = csvFileContent.slice(csvFileContent.indexOf("\n") + 1).split("\n");

        const arr = rows.map(function (row) {
          const values = row.split(delimiter);
          const el = headers.reduce(function (object, header, index) {
            object[header] = values[index];
            return object;
          }, {});
          return el;
        });


        that.data.withHeadings = true;

        const tableContent = [];
        tableContent.push(Object.keys(arr[0]));

        for (let i = 0; i < arr.length; i++) {
          tableContent.push(Object.values(arr[i]));
        }

        that.data.content = tableContent;

        const tempData = {};
        tempData.content = tableContent;
        tempData.withHeading = true;

        const oldTable = that.container.querySelectorAll('.tc-wrap');
        oldTable[0].remove();

        that.table = new Table(that.readOnly, that.api, tempData, that.config);
        that.container.appendChild(that.table.getWrapper());
        that.table.setHeadingsSetting(that.data.withHeadings);

        that.csvFileInput = that.container.querySelector('.csv-file-input');
        that.csvFileInput.addEventListener('change', (event) => {
          that.csvFileHandler(event);
        });
      }
      reader.onerror = function (e) {
        console.log('ERROR READING CSV FILE');
      }
    }
  }

  /**
   * Return Tool's view
   *
   * @returns {HTMLDivElement}
   */
  render() {
    /** creating table */
    this.table = new Table(this.readOnly, this.api, this.data, this.config);

    /** creating container around table */
    this.container = $.make('div', this.api.styles.block);
    this.container.appendChild(this.table.getWrapper());
    this.table.setHeadingsSetting(this.data.withHeadings);
    this.csvFileInput = this.container.querySelector('.csv-file-input');

    if (!this.readOnly) {
      this.csvFileInput.addEventListener('change', (event) => {
        this.csvFileHandler(event);
      });
    }

    return this.container;
  }

  /**
   * Add plugin settings
   *
   * @returns {HTMLElement} - wrapper element
   */
  renderSettings() {
    const wrapper = $.make('div', 'cdx-table-settings');
    // const tunes = [
    //   {
    //     name: this.api.i18n.t('With headings'),
    //     icon: IconTableWithHeadings,
    //     isActive: this.data.withHeadings,
    //     setTune: () => {
    //       this.data.withHeadings = true;
    //     }
    //   },
    //   {
    //     name: this.api.i18n.t('Without headings'),
    //     icon: IconTableWithoutHeadings,
    //     isActive: !this.data.withHeadings,
    //     setTune: () => {
    //       this.data.withHeadings = false;
    //     }
    //   },
    // ];

    // tunes.map(tune => {
    //   const button = document.createElement('button');
    //   button.classList.add(this.api.styles.settingsButton);
    //   button.innerHTML = tune.icon;

    //   if (tune.isActive) {
    //     button.classList.add(this.api.styles.settingsButtonActive);
    //   } else {
    //     button.classList.remove(this.api.styles.settingsButtonActive);
    //   }

    //   button.addEventListener('click', () => this.toggleTune(tune, button));

    //   this.api.tooltip.onHover(button, tune.name, {
    //     placement: 'top',
    //     hidingDelay: 500
    //   });

    //   wrapper.appendChild(button);
    //   return button
    // });
    return wrapper;
  }


  /**
   * Extract table data from the view
   *
   * @returns {TableData} - saved data
   */
  save() {
    const tableContent = this.table.getData();

    let result = {
      withHeadings: this.data.withHeadings,
      content: tableContent,
    };
    return result;
  }

  /**
   * Changes the state of the tune
   * Updates its representation in the table
   *
   * @param {Tune} tune - one of the table settings
   * @param {HTMLElement} tuneButton - DOM element of the tune
   * @returns {void}
   */
  toggleTune(tune, tuneButton) {
    const buttons = tuneButton.parentNode.querySelectorAll('.' + this.api.styles.settingsButton);

    // Clear other buttons
    Array.from(buttons).forEach((button) =>
      button.classList.remove(this.api.styles.settingsButtonActive)
    );

    // Mark active button
    tuneButton.classList.toggle(this.api.styles.settingsButtonActive);
    tune.setTune();

    this.table.setHeadingsSetting(this.data.withHeadings);
  }

  /**
   * Plugin destroyer
   *
   * @returns {void}
   */
  destroy() {
    this.table.destroy();
  }
}
