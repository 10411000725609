import React, { Component } from 'react';

// Modules
import ContentLoader from 'react-content-loader';

// UI components
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";

class HomeSkeletonScreen extends Component {  
  constructor() {
    super();
    this.state = {
      backgroundColor: '#f3f3f3',
      foregroundColor: '#ecebeb',
    };
  }

  renderHomeItems = (itemClassList) => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 4;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col xs={12} md={6} xl={3} key={'item-' + i} className="mb-4">
          <div className={itemClassList} style={{paddingBottom: 0, border: 'none'}}>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 263 396"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="0" y="353.077" width="165.26" height="16" rx="8" ry="8" />
              <rect x="0" y="328.272" width="224.102" height="16" rx="8" ry="8" />
              <rect x="0" y="303.468" width="262.356" height="16" rx="8" ry="8" />
              <rect x="0" y="266.083" width="139.746" height="25" rx="12.5" ry="12.5" />
              <rect x="0" y="234.336" width="224.102" height="25" rx="12.5" ry="12.5" />
              <rect x="0" y="202.588" width="262.356" height="25" rx="12.5" ry="12.5" />
              <rect x="0" y="32.65" width="262.356" height="147.575" rx="2.5" ry="2.5" />
              <rect x="26.662" y="0" width="109.924" height="20" rx="10" ry="10"/>
              <rect x="0" y="0" width="18.133" height="20" rx="5" ry="5" />
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  renderHomeSocialItems = () => {

    let items = [];
    let numberOfItems;

    if (this.props.numberOfItems) {
      numberOfItems = this.props.numberOfItems;
    } else {
      numberOfItems = 2;
    }

    for (let i = 0; i < numberOfItems; i++) {

      items.push(
        <Col xs={12} md={6} key={'social-' + i} className="mb-4">
          <div>
            <ContentLoader 
              speed={2}
              width={'100%'}
              height={'100%'}
              viewBox="0 0 555 536"
              backgroundColor={this.state.backgroundColor}
              foregroundColor={this.state.foregroundColor}
            >
              <rect x="0" y="33.235" width="554.802" height="502.572" rx="5" ry="5" />
              <rect x="29.521" y="0" width="61.366" height="20" rx="10" ry="10" />
              <rect x="102.017" y="0" width="160.339" height="20" rx="10" ry="10" />
              <rect x="0" y="0" width="22.584" height="20" rx="10" ry="10" />
            </ContentLoader>
          </div>
        </Col>
      );

    }

    return items;
  };

  render() {
    const itemClass = "home-item ";
    const itemClassList = `${itemClass}  item`;

    return (
      <main className="home  screen">

        {/* Title */}
        <div className="welcome">
          <ContentLoader 
            speed={2}
            width={'100%'}
            height={'100%'}
            viewBox="0 0 1140 117"
            backgroundColor={this.state.backgroundColor}
            foregroundColor={this.state.foregroundColor}
          >
            <rect x="0" y="0" width="236.444" height="50" rx="10" ry="10" />
            <rect x="0" y="0" width="355.948" height="50" rx="10" ry="10" />
            <rect x="0" y="66.63" width="293.893" height="50" rx="10" ry="10" />
          </ContentLoader>
        </div>

        {/* Home items */}
        <Row className="mt-5">
          {this.renderHomeItems(itemClassList)}
        </Row>

        {/* Home social items */}
        <Row className="mt-5">
          {this.renderHomeSocialItems()}
        </Row>

      </main>
    );
  }

}

export default HomeSkeletonScreen;